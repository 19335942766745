import React from 'react';
import flatten from 'flat';
import { IntlProvider } from 'react-intl';
import { Switch, Route, Redirect } from 'react-router-dom';

import ScrollToTop from './components/pages/common/scroll-to-top/scroll-to-top';
import { translation } from './translations/translations';
import { PAGES } from './datas/pages/pages';
import * as PAGE from './components/pages';
import { Navbar } from './components/navbar/navbar';
import { Footer } from './components/footer/footer';

export default ({ Router, location, context }) => (
  <IntlProvider locale="hu" messages={flatten(translation)}>
    <Router location={location} context={context}>
      <ScrollToTop />
      <Navbar />
      <div className="main-content">
        <Switch>
          <Route exact path={PAGES.home.url}>
            <PAGE.Home />
          </Route>
          <Route exact path={PAGES.workplaces.url}>
            <PAGE.Workplaces />
          </Route>
          <Route exact path={PAGES.education.url}>
            <PAGE.Education />
          </Route>
          <Route exact path={PAGES.programmingSkills.url}>
            <PAGE.ProgrammingSkills />
          </Route>
          <Route exact path={PAGES.leisureTime.url}>
            <PAGE.LeisureTime />
          </Route>
          <Route exact path={PAGES.notfound.url}>
            <PAGE.NotFound />
          </Route>
          <Redirect to={PAGES.notfound.url} />
        </Switch>
      </div>
      <Footer />
    </Router>
  </IntlProvider>
);
