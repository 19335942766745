import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { Link as InsideLink } from 'react-router-dom';

export const AnimatedLink = ({ text, link }) => {
  if (link.startsWith('/')) {
    return (
      <InsideLink to={link} className="component-link animated">
        <div className="custom-link-text d-inline">{text}</div>
        <FontAwesomeIcon icon={faAngleRight} fixedWidth />
      </InsideLink>
    );
  } else {
    return (
      <a
        className="component-link animated"
        target="_blank"
        rel="noreferrer"
        href={link}
      >
        <div className="custom-link-text">{text}</div>
        <FontAwesomeIcon icon={faAngleRight} fixedWidth />
      </a>
    );
  }
};

AnimatedLink.prototype = {
  /**
   * Text of link
   */
  text: PropTypes.string.isRequired,
  /**
   * Internal or external link.
   * (internal starts with / charachter)
   */
  link: PropTypes.string.isRequired,
};
