import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faGlobeEurope } from '@fortawesome/free-solid-svg-icons';

import * as Layout from '../layout';
import { datas } from '../../datas/infos/infos';

export const Footer = () => (
  <footer>
    <Layout.Container>
      <Layout.Row>
        <Layout.Col className="mt-2x" col={12}>
          <FormattedMessage
            id="footer.lastUpdate"
            values={{ date: datas.lastUpdate }}
          />
        </Layout.Col>
      </Layout.Row>
    </Layout.Container>
    <Layout.Container fluid={true}>
      <Layout.Row>
        <Layout.Col col={12}>
          <hr />
          <div className="text-center footer-content">
            <span>
              <FormattedMessage id="footer.copyright" /> (
            </span>
            <a href={`mailto:${datas.profile.email}`}>
              <FontAwesomeIcon icon={faEnvelope} fixedWidth />
              <span className="d-none d-md-inline">{datas.profile.email}</span>
            </a>
            <span>, </span>
            <a href={datas.profile.website} target="_blank" rel="noreferrer">
              <FontAwesomeIcon icon={faGlobeEurope} fixedWidth />
              <span className="d-none d-md-inline">
                {datas.profile.website}
              </span>
            </a>
            <span>)</span>
          </div>
        </Layout.Col>
      </Layout.Row>
    </Layout.Container>
  </footer>
);
