import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGraduationCap } from '@fortawesome/free-solid-svg-icons';

import * as Layout from '../../layout';
import { addHTMLSeo } from '../../../utils/html-seo/html-seo';
import { DataView } from '../../common/data-view/data-view';
import { BoxWithPapers } from '../../common/box/box-with-papers';
import { datas } from '../../../datas/infos/infos';

export const Education = () => {
  const intl = useIntl();

  useEffect(() => {
    addHTMLSeo({
      title: intl.formatMessage({ id: 'pages.education' }),
      url: window.location.pathname,
      keywords: datas.educations.map((education) => education.title),
    });
  }, [intl]);

  return (
    <Layout.Container>
      <Layout.Row>
        <Layout.Col col={12}>
          <BoxWithPapers
            header={
              <h1>
                <FontAwesomeIcon icon={faGraduationCap} fixedWidth />{' '}
                <FormattedMessage id="pages.education" />
              </h1>
            }
          >
            {datas.educations.map((education, index) => (
              <div key={index}>
                <div className="mb mt-2x">
                  <h2 className="h3">
                    {education.title}{' '}
                    <span className="h5">({education.period})</span>
                  </h2>
                </div>
                <div>
                  {education.details.map((detail, index) => (
                    <DataView key={index} data={detail} />
                  ))}
                </div>
              </div>
            ))}
          </BoxWithPapers>
        </Layout.Col>
      </Layout.Row>
    </Layout.Container>
  );
};
