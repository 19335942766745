export const translation = {
  title: 'Csúri Krisztián',
  description: 'Csúri Krisztián önéletrajz',
  keywords: {
    cv: 'önéletrajz',
  },
  hamburgermenu: 'Menü kinyitása',
  pages: {
    home: 'Kezdőlap',
    profile: 'Bemutatkozás',
    workplaces: 'Munkahelyek',
    education: 'Tanulmányok',
    programmingSkills: 'Programozói kompetenciák',
    leisureTime: 'Szabadidő',
    notFound: 'Az oldal nem található',
  },
  social: {
    linkedin: 'LinkedIn',
    facebook: 'Facebook',
  },
  workplace: {
    link: 'Link a(z) {name} oldalára',
  },
  footer: {
    lastUpdate: 'Frissítve: {date}',
    copyright: 'Copyright © Csúri Krisztián 2024',
  },
};
