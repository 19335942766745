import React from 'react';

import { SimpleText } from './simple-text/simple-text';
import { Listing } from './listing/listing';

export const DataView = ({ data }) => {
  if (typeof data === 'string') {
    return <SimpleText data={data} />;
  } else {
    switch (data.type) {
      case 'list':
        return <Listing datas={data.values} />;
      default:
        return null;
    }
  }
};
