import React, {useEffect} from 'react';
import { useIntl } from 'react-intl';

import * as Layout from '../../layout';
import { addHTMLSeo } from '../../../utils/html-seo/html-seo';
import { Profile } from './profile/profile';
import { WorkplaceAndEducation } from './workplace-and-education/workplace-and-education';
import { LeisureTime } from './leisure-time/leisure-time';
import { ProgrammingSkills } from './programming-skills/programming-skills';

export const Home = () => {
  const intl = useIntl();

  useEffect(() => {
    addHTMLSeo({
      url: window.location.pathname,
      title: intl.formatMessage({ id: 'pages.home' }),
    });
  }, [intl]);

  return (
    <Layout.Container>
      <Profile />
      <WorkplaceAndEducation />
      <ProgrammingSkills />
      <LeisureTime />
    </Layout.Container>
  );
};
