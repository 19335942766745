import React from 'react';
import PropTypes from 'prop-types';

import { AnimatedLink } from '../../link/animated-link';

export const BoxContent = ({ header, link, children }) => (
  <div className="box-content">
    <div>
      {header ? (
        <div className="box-header">
          <div className="box-header-title">{header}</div>
          <hr className="small" />
        </div>
      ) : null}
      <div>{children}</div>
    </div>
    {link ? (
      <div className="box-footer text-center">
        <AnimatedLink text="Tovább" link={link} />
      </div>
    ) : null}
  </div>
);

BoxContent.prototype = {
  /**
   * Header of box.
   */
  header: PropTypes.string,
  /**
   * Internal or external link.
   * (internal starts with / charachter)
   */
  link: PropTypes.string,
  /**
   * Box content.
   */
  children: PropTypes.element.isRequired,
};
