import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase, faLink } from '@fortawesome/free-solid-svg-icons';

import * as Layout from '../../layout';
import { addHTMLSeo } from '../../../utils/html-seo/html-seo';
import { BoxWithPapers } from '../../common/box/box-with-papers';
import { datas } from '../../../datas/infos/infos';

export const Workplaces = () => {
  const intl = useIntl();

  useEffect(() => {
    addHTMLSeo({
      title: intl.formatMessage({ id: 'pages.workplaces' }),
      url: window.location.pathname,
      keywords: Array.from(
        new Set(datas.workplaces.map((worplace) => worplace.workplace))
      ),
    });
  }, [intl]);

  return (
    <Layout.Container>
      <Layout.Row>
        <Layout.Col col={12}>
          <BoxWithPapers
            header={
              <h1>
                <FontAwesomeIcon icon={faBriefcase} fixedWidth />{' '}
                <FormattedMessage id="pages.workplaces" />
              </h1>
            }
          >
            {datas.workplaces.map((worplace, index) => (
              <div key={index}>
                <div className="mb mt-2x">
                  <h2 className="h3">
                    {worplace.workplace}{' '}
                    <span className="h5">({worplace.period})</span>{' '}
                    <a
                      href={worplace.link}
                      target="_blank"
                      className="h5"
                      rel="noreferrer"
                      aria-label={intl.formatMessage(
                        { id: 'workplace.link' },
                        { name: worplace.workplace }
                      )}
                    >
                      <FontAwesomeIcon icon={faLink} fixedWidth />
                    </a>
                  </h2>
                  <div>
                    <i>({worplace.position})</i>
                  </div>
                </div>
                <div>
                  {worplace.details.map((detail, index) => (
                    <p key={index}>{detail}</p>
                  ))}
                </div>
              </div>
            ))}
          </BoxWithPapers>
        </Layout.Col>
      </Layout.Row>
    </Layout.Container>
  );
};
