import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGamepad } from '@fortawesome/free-solid-svg-icons';

import * as Layout from '../../../layout';
import { Anchor } from '../../../common/anchor/anchor';
import { DataView } from '../../../common/data-view/data-view';
import { Box } from '../../../common/box/box';
import { datas } from '../../../../datas/infos/infos';
import { PAGES } from '../../../../datas/pages/pages';

export const LeisureTime = () => (
  <Layout.Row>
    <Layout.Col col={12}>
      <Anchor hash={PAGES.leisureTime.hash} />
      <Box
        header={
          <h2>
            <FontAwesomeIcon icon={faGamepad} fixedWidth />{' '}
            <FormattedMessage id="pages.leisureTime" />
          </h2>
        }
        link={PAGES.leisureTime.url}
      >
        {datas.leisureTime.short.map((info, index) => (
          <DataView key={index} data={info} />
        ))}
      </Box>
    </Layout.Col>
  </Layout.Row>
);
