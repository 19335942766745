export const PAGES = {
  home: {
    url: '/',
    navbar: true,
    hash: 'profile',
  },
  notfound: {
    url: '/404',
    navbar: false,
  },
  workplaces: {
    url: '/workplaces',
    navbar: true,
    hash: 'workplaces',
  },
  education: {
    url: '/education',
    navbar: true,
    hash: 'education',
  },
  programmingSkills: {
    url: '/programmingskills',
    navbar: true,
    hash: 'programmingSkills',
  },
  leisureTime: {
    url: '/leisuretime',
    navbar: true,
    hash: 'leisureTime',
  },
};

export const PAGE_LINKS = Object.keys(PAGES).map((currentPage) => {
  const page = PAGES[currentPage];
  let url = page.url;
  if (page.pathParam) {
    url += `/:${page.pathParam}`;
  }
  return url;
});
