import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBriefcase,
  faGraduationCap,
} from '@fortawesome/free-solid-svg-icons';

import * as Layout from '../../../layout';
import { Anchor } from '../../../common/anchor/anchor';
import { Box } from '../../../common/box/box';
import { BoxContent } from '../../../common/box/box-content/box-content';
import { datas } from '../../../../datas/infos/infos';
import { PAGES } from '../../../../datas/pages/pages';

export const WorkplaceAndEducation = () => (
  <>
    <div className="d-none d-lg-block">
      <Layout.Row>
        <Layout.Col col={12}>
          <Box>
            <Layout.Row>
              <Layout.Col col={12} lg={6}>
                <Anchor hash={PAGES.workplaces.hash} />
                <BoxContent
                  header={
                    <h2>
                      <FontAwesomeIcon icon={faBriefcase} fixedWidth />{' '}
                      <FormattedMessage id="pages.workplaces" />
                    </h2>
                  }
                  link={PAGES.workplaces.url}
                >
                  <table>
                    <tbody>
                      {datas.workplaces.map((info, index) =>
                        info.hidden ? null : (
                          <tr key={index}>
                            <td className="nowrap">{info.period} </td>
                            <td>
                              <span className="d-block d-xl-inline">
                                <a
                                  className="underline"
                                  href={info.link}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {info.workplace}
                                </a>
                              </span>
                              <i className="d-block  d-xl-inline">
                                {' '}
                                ({info.position})
                              </i>
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </BoxContent>
              </Layout.Col>
              <Layout.Col col={12} lg={6}>
                <Anchor hash={PAGES.education.hash} />
                <BoxContent
                  header={
                    <h2>
                      <FontAwesomeIcon icon={faGraduationCap} fixedWidth />{' '}
                      <FormattedMessage id="pages.education" />
                    </h2>
                  }
                  link={PAGES.education.url}
                >
                  <table>
                    <tbody>
                      {datas.educations.map((info, index) => (
                        <tr key={index}>
                          <td className="nowrap">{info.period} </td>
                          <td>{info.title}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </BoxContent>
              </Layout.Col>
            </Layout.Row>
          </Box>
        </Layout.Col>
      </Layout.Row>
    </div>
    <div className="d-block d-lg-none">
      <Layout.Row>
        <Layout.Col col={12}>
          <Box>
            <Anchor hash={PAGES.workplaces.hash} />
            <BoxContent
              header={
                <h2>
                  <FontAwesomeIcon icon={faBriefcase} fixedWidth />{' '}
                  <FormattedMessage id="pages.workplaces" />
                </h2>
              }
              link={PAGES.workplaces.url}
            >
              <table>
                <tbody>
                  {datas.workplaces.map((info, index) =>
                    info.hidden ? null : (
                      <tr key={index}>
                        <td className="nowrap">{info.period} </td>
                        <td>
                          <span className="d-block d-xl-inline">
                            <a
                              className="underline"
                              href={info.link}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {info.workplace}
                            </a>
                          </span>
                          <i className="d-block  d-xl-inline">
                            {' '}
                            ({info.position})
                          </i>
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </BoxContent>
          </Box>
        </Layout.Col>
      </Layout.Row>
      <Layout.Row>
        <Layout.Col col={12}>
          <Box>
            <Anchor hash={PAGES.education.hash} />
            <BoxContent
              header={
                <h2>
                  <FontAwesomeIcon icon={faGraduationCap} fixedWidth />{' '}
                  <FormattedMessage id="pages.education" />
                </h2>
              }
              link={PAGES.education.url}
            >
              <table>
                <tbody>
                  {datas.educations.map((info, index) => (
                    <tr key={index}>
                      <td className="nowrap">{info.period} </td>
                      <td>{info.title}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </BoxContent>
          </Box>
        </Layout.Col>
      </Layout.Row>
    </div>
  </>
)
