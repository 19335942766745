import React from 'react';
import PropTypes from 'prop-types';

export const BoxWithTape = ({ children }) => (
  <div className='box-with-tape-content'>
    <div className="box-inner">
      <div className="tape-section"></div>
      {children}
      <div className="tape-section"></div>
    </div>
  </div>
)

BoxWithTape.prototype = {
  /**
   * Box content.
   */
  children: PropTypes.element.isRequired,
};


