export const datas = {
  lastUpdate: '2024.08.30.',
  profile: {
    name: 'Csúri Krisztián',
    education: 'Szoftverfejlesztő',
    birthDate: '1989-07-29',
    location: 'Szeged',
    email: 'csuri.krisztian89@gmail.com',
    linkedin:
      'https://www.linkedin.com/in/kriszti%C3%A1n-cs%C3%BAri-02a6b314a/',
    facebook: 'https://www.facebook.com/krisztian.csuri.35',
    website: 'https://kcsuri.hu/',
  },
  workplaces: [
    {
      period: '2024 szept. -',
      workplace: 'Mindstorm Kutató És Fejlesztő Korlátolt Felelősségű Társaság',
      link: 'https://mindstorm.hu/',
      position: 'Szoftverfejlesztő',
      hidden: false,
      details: [''],
    },
    {
      period: '2022 jan. - 2024 máj.',
      workplace: 'IKRON Fejlesztő és Szolgáltató Kft.',
      link: 'https://ikron.hu/',
      position: 'Full Stack fejlesztő',
      hidden: false,
      details: [
        'Az Ikron Fejlesztő és Szolgáltató Kft. csapatában elsődleges feladatom a fejlesztési osztály tagjaként a PO-k által megtervezett funkciók implementálása volt Zkoss keretrendszerben megvalósított ERP szoftverben. Napi munkámhoz tartozott az új igények implementálása, fejlesztések review-ja, valamint support bejelentések vizsgálata és javítása. Ezek mellett amikor lehetőség adódott rá részt vettem tervezési feladatokban is.',
        'Munkám során büszke vagyok arra, hogy sikerült új szemléletmódú fejlesztést behoznom a csapatba és bizonyítanom annak előnyeit, korábbi third-party függőséget megszüntetni, valamint előkészíteni a Maven átállást.',
      ],
    },
    {
      period: '2021 ápr. -	2022 jan.',
      workplace: 'EPAM Systems',
      link: 'https://www.epam.com/',
      position: 'JavaScript fejlesztő',
      hidden: false,
      details: [
        'Az EPAM Systems Kft.-nél lehetőségem adódott megismerkedni a multinacionális vállalati légkörrel. Találkozni a nagy projektek-sok kis csapat közös munka összetetségével, valamint megismerkedni a React programozási nyelv alapjaival.',
      ],
    },
    {
      period: '2013 nov. - 2021 márc.',
      workplace: 'Monguz Kft.',
      link: 'https://qulto.eu/',
      position: 'Full Stack fejlesztő',
      hidden: false,
      details: [
        'A Monguz Kft. csapatában Full Stack fejlesztőként elsősorban JAVA, JavaScript, HTML nyelvekkel dolgoztam, valamint PostgreSQL és Apache Solr adatbázisokkal. Elsősorban portletek (Liferay 6.0.6 és magasabb környezetben) valamint Tomcat Webapp-okat fejlesztettem.',
        'A termékfejlesztő csapat tagjaként részt vettem a webes könyvtári katalógus (OPAC) fejlesztésében és support-jában.',
        'Munkám során feladatom volt Liferay telepítés, konfiguráció, egyedi design készítése az ügyfelek elképzelései alapján, valamint egyedi Liferay portletek megvalósítása.',
        'Munkámhoz tartozott support feladatok ellátása, valamint régi portálok megújítása a mai követelmények (responsivitás, minimális HTML SEO …stb) figyelembevételével és az oldalak korábbi design-jának megtartásával.',
      ],
    },
    {
      period: '2020 jún. - 2020 nov.',
      workplace: 'Keresztpont Egyesület',
      link: 'https://keresztpont.hu/',
      position: 'Adminisztrátor (részmunkaidős)',
      hidden: true,
      details: [
        '2020 júniusa és novembere között részmunkaidős alkalmazotként heti 10 órában segítettem a Keresztpont egyesület munkáját adminisztrációs munkakörben.',
      ],
    },
  ],
  educations: [
    {
      period: '2017',
      title: 'DExam egynyelvű B2 komplex angol nyelvvizsga',
      details: [
        '2017 őszén sikeres B2 (középfokú) nyelvvizsgát tettem angol nyelvből (DExam nyelvvizsga).',
      ],
    },
    {
      period: '2008 - 2014',
      title:
        'Szegedi Tudományegyetem Természettudományi és Informatikai kar PTI BSC_N',
      details: [
        'A programtervező informatik kar alapképzésében szereplő tárgyakat sikeresen teljesítettem. Záróvizsgát nem tettem.',
      ],
    },
    {
      period: '2008',
      title: 'Érettségi',
      details: [
        'Sikeres érettségi: Magyar nyelv és irodalom, Matematika, Történelem, Német nyelv, Informatika(emelt) tantárgyakból.',
      ],
    },
  ],
  programmingSkills: {
    skillMatriy: [
      {
        name: 'Java',
        weight: 80,
        color: '#d53328',
        target: 'https://www.java.com/',
      },
      {
        name: 'ZKoss',
        weight: 70,
        color: '#00A2CF',
        target: 'https://www.zkoss.org/',
      },
      {
        name: 'Spring',
        weight: 60,
        color: '#6db33f',
        target: 'https://spring.io/',
      },
      {
        name: 'Maven',
        weight: 60,
        color: '#CF3C2D',
        target: 'https://maven.apache.org/',
      },
      {
        name: 'JavaScript',
        weight: 50,
        color: '#d6ba33',
        target: 'https://www.javascript.com/',
      },
      {
        name: 'JQuery',
        weight: 50,
        color: '#2676a9',
        target: 'https://jquery.com/',
      },
      {
        name: 'HTML5',
        weight: 40,
        color: '#e54d28',
      },
      {
        name: 'PostgreSQL',
        weight: 30,
        color: '#336791',
        target: 'https://www.postgresql.org/',
      },
      {
        name: 'JPA',
        weight: 30,
        color: '#6CB52D',
      },
      {
        name: 'Apache Solr',
        weight: 25,
        color: '#da4327',
        target: 'https://lucene.apache.org/solr/',
      },
      {
        name: 'Git',
        weight: 25,
        color: '#E24329',
        target: 'https://git-scm.com/',
      },
      {
        name: 'Liferay',
        weight: 10,
        color: '#1c46ba',
        target: 'https://www.liferay.com/',
      },
      {
        name: 'REACT',
        weight: 10,
        color: '#5DD4F2',
        target: 'https://hu.reactjs.org/',
      },
      {
        name: 'Apache Tomcat',
        weight: 10,
        color: '#d1a41b',
        target: 'http://tomcat.apache.org/',
      },
      {
        name: 'Mercurial',
        weight: 10,
        color: '#999999',
      },
      {
        name: 'Sass',
        weight: 8,
        color: '#cd6799',
        target: 'https://sass-lang.com/',
      },
      {
        name: 'Bootstrap',
        weight: 4,
        color: '#563d7c',
        target: 'https://getbootstrap.com/',
      },
      {
        name: 'FontAwsome',
        weight: 4,
        color: '#237285',
        target: 'https://fontawesome.com/',
      },
    ],
    details: [
      'Munkám során kezdetben <b style="color:#999999">Mercurial</b> később <b style="color:#E24329">Git</b> verziókezelő segítségével végeztem napi feladataimat.',
      'Napi szinten <b style="color:#d53328">Java 8</b>-at használok.',
      'Az Ikron Fejlesztő és Szolgáltató Kft.-nél elsősorban backend fejlesztési feladatokkal foglalkozom, mely során <b style="color:#d53328">Java</b> programozási nyelven <b style="color:#00A2CF">ZKoss</b> keretrendszerben fejlesztek <b style="color:#336791">PostgreSQL</b> és <b style="color:#6CB52D">JPA</b> segítségével.',
      'Az EPAM Systems Kft.-nél lehetőségem nyílt megismerkedni a  <b style="color:#5DD4F2">React</b> programozási nyelv alapjaival.',
      'A Monguz kft.-nél elsősorban webfejlesztői tapasztalatokat szereztem: <b style="color:#1c46ba">Liferay</b> portleteket és <b style="color:#d1a41b">Apache Tomcat</b> webappokat fejlesztettem <b style="color:#563d7c">Bootstrap</b> (Bootstrap 3, Bootstrap 4) segítségével. Rendszeresen használtam: <b style="color:#d6ba33">JavaScript</b>-et, <b style="color:#2676a9">JQuery</b>-t, <b style="color:#cd6799">Sass</b>-t, <b style="color:#237285">FontAwsome</b>-t. Szerver oldalon leggyakrabban <b style="color:#CF3C2D">Maven</b> projektekkel, <b style="color:#336791">PostgreSQL</b> és <b style="color:#da4327">Apache Solr</b> adatbázisokkal találkoztam, valamint: <b style="color:#6db33f">Java Spring</b>-el, <b style="color:#81E32C">Swagger</b>-el, <b>NamedParameterJdbcTemplate</b>-el.',
    ],
  },
  leisureTime: {
    short: [
      'Szabadidőmben szeretek biciklizni, kertet gondozni, barkácsolni, varni és amikor lehetőség adódik rá nagyobb társaságnak főzni (például közösségi tervező hétvégéken).',
      'Szervezőként és önkéntesként segítem a <a class="underline" href="https://keresztpont.hu/" target="_blank">Keresztpont Közösség</a> munkáját: részt veszek rendezvények előkészítésében és lebonyolításában. Önkéntesként a közösség mögött álló egyesület pénzforgalmát (számláit) és hivatalos papírjait tartom nyilván: tárolom és készítem elő könyvelésre egy saját fejlesztésű webes alkalmazás segítségével.',
      'Korábban a <a class="underline" href="https://uniref-szeged.hu/" target="_blank">Szegedi Református Egyetemi Gyülekezet</a> aktív tagjaként a közösség munkáját segítettem. Legnagyobb sikeremnek ezekből az évekből a közösség honlapjának megvalósítását érzem, aminek része volt egy saját miniCRM írása, egy React-es frontend készítése valamint ezek telepítése és üzemeltetése egy VPS szerveren az Nginx-től a PostgreSQL adatbázisig.',
    ],
    long: [
      'Szabadidőmben szeretek biciklizni, kertet gondozni, barkácsolni, varni és amikor lehetőség adódik rá nagyobb társaságnak főzni (például közösségi tervező hétvégéken).',
      'Szervezőként és önkéntesként segítem a <a class="underline" href="https://keresztpont.hu/" target="_blank">Keresztpont Közösség</a> munkáját: részt veszek rendezvények előkészítésében és lebonyolításában. Önkéntesként a közösség mögött álló egyesület pénzforgalmát (számláit) és hivatalos papírjait tartom nyilván: tárolom és készítem elő könyvelésre egy saját fejlesztésű webes alkalmazás segítségével.',
      'Korábban a <a class="underline" href="https://uniref-szeged.hu/" target="_blank">Szegedi Református Egyetemi Gyülekezet</a> aktív tagjaként a közösség munkáját segítettem. Legnagyobb sikeremnek ezekből az évekből a közösség honlapjának megvalósítását érzem, aminek része volt egy saját miniCRM írása, egy React-es frontend készítése valamint ezek telepítése és üzemeltetése egy VPS szerveren az Nginx-től a PostgreSQL adatbázisig.',
    ],
  },
};
