import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCode } from '@fortawesome/free-solid-svg-icons';

import * as Layout from '../../layout';
import { addHTMLSeo } from '../../../utils/html-seo/html-seo';
import { DataView } from '../../common/data-view/data-view';
import { BoxWithPapers } from '../../common/box/box-with-papers';
import { datas } from '../../../datas/infos/infos';

export const ProgrammingSkills = () => {
  const intl = useIntl();

  useEffect(() => {
    addHTMLSeo({
      title: intl.formatMessage({ id: 'pages.programmingSkills' }),
      url: window.location.pathname,
      keywords: datas.programmingSkills.skillMatriy.map((item) => item.name),
    });
  }, [intl]);

  useEffect(() => {
    window.updateTagCloud(document.getElementById('programming-skills-cloud'));
    window.dispatchEvent(new Event('resize'));
  }, []);

  return (
    <Layout.Container>
      <Layout.Row>
        <Layout.Col col={12}>
          <BoxWithPapers
            header={
              <h1>
                <FontAwesomeIcon icon={faCode} fixedWidth />{' '}
                <FormattedMessage id="pages.programmingSkills" />
              </h1>
            }
          >
            <div id="programming-skills-cloud" className="mt-2x mb-4x" data-cloud>
              {datas.programmingSkills.skillMatriy.map((item, index) => {
                const { name, color, weight, target } = item;
                return (
                  <input
                    key={index}
                    type="hidden"
                    name="skill"
                    value={name}
                    data-weight={weight}
                    data-color={color}
                    data-target={target}
                  />
                );
              })}
            </div>
            {datas.programmingSkills.details.map((info, index) => (
              <DataView key={index} data={info} />
            ))}
          </BoxWithPapers>
        </Layout.Col>
      </Layout.Row>
    </Layout.Container>
  );
};
