import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarAlt,
  faMapMarkedAlt,
  faEnvelope,
} from '@fortawesome/free-solid-svg-icons';
import {
  faLinkedin,
  faFacebookSquare,
} from '@fortawesome/free-brands-svg-icons';

import * as Layout from '../../../layout';
import { Anchor } from '../../../common/anchor/anchor';
import { BoxWithTape } from '../../../common/box/box-with-tape';
import { BoxWithPapers } from '../../../common/box/box-with-papers';
import { datas } from '../../../../datas/infos/infos';
import { PAGES } from '../../../../datas/pages/pages';

export const Profile = () => (
  <Layout.Row className="home-profile">
    <Layout.Col col={12} sm={4} lg={3}>
      <div className="d-block">
        <BoxWithTape>
          <img
            className="profile-img"
            src="/images/profile.png"
            alt={datas.profile.name}
          />
        </BoxWithTape>
      </div>
    </Layout.Col>
    <Layout.Col col={12} sm={8} lg={9}>
      <Anchor hash={PAGES.home.hash} />
      <BoxWithPapers header={<h1>{datas.profile.name}</h1>}>
        <>
          <div className="mb">
            <i>{datas.profile.education}</i>
          </div>
          <div className="mb">
            <FontAwesomeIcon icon={faCalendarAlt} fixedWidth />{' '}
            {datas.profile.birthDate}
          </div>
          <div className="mb">
            <FontAwesomeIcon icon={faMapMarkedAlt} fixedWidth />{' '}
            {datas.profile.location}
          </div>
          <div className="mb">
            <div>
              <FontAwesomeIcon icon={faEnvelope} fixedWidth />{' '}
              <a className="underline" href={`mailto:${datas.profile.email}`}>
                {datas.profile.email}
              </a>
            </div>
            <div>
              <FontAwesomeIcon icon={faLinkedin} fixedWidth />{' '}
              <a className="underline" href={datas.profile.linkedin} target="_blank" rel="noreferrer">
                <FormattedMessage id="social.linkedin" />
              </a>
            </div>
            <div>
              <FontAwesomeIcon icon={faFacebookSquare} fixedWidth />{' '}
              <a className="underline" href={datas.profile.facebook} target="_blank" rel="noreferrer">
                <FormattedMessage id="social.facebook" />
              </a>
            </div>
          </div>
        </>
      </BoxWithPapers>
    </Layout.Col>
  </Layout.Row>
);
