import { Component } from 'react';
import { withRouter } from 'react-router-dom';

class ScrollToTop extends Component {
  componentDidMount() {
    const hash = window?.location?.hash?.substring(1);
    if (hash && hash.length > 0) {
      document.getElementById(hash).scrollIntoView();
    }
  }

  componentDidUpdate() {
    const hash = window?.location?.hash?.substring(1);
    if (hash && hash.length > 0) {
      document.getElementById(hash).scrollIntoView();
    } else {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return null;
  }
}

export default withRouter(ScrollToTop);
