import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGamepad } from '@fortawesome/free-solid-svg-icons';

import * as Layout from '../../layout';
import { addHTMLSeo } from '../../../utils/html-seo/html-seo';
import { DataView } from '../../common/data-view/data-view';
import { BoxWithPapers } from '../../common/box/box-with-papers';
import { datas } from '../../../datas/infos/infos';

export const LeisureTime = () => {
  const intl = useIntl();

  useEffect(() => {
    addHTMLSeo({
      title: intl.formatMessage({ id: 'pages.leisureTime' }),
      url: window.location.pathname,
    });
  }, [intl]);

  return (
    <Layout.Container>
      <Layout.Row>
        <Layout.Col col={12}>
          <BoxWithPapers
            header={
              <h1>
                <FontAwesomeIcon icon={faGamepad} fixedWidth />{' '}
                <FormattedMessage id="pages.leisureTime" />
              </h1>
            }
          >
            {datas.leisureTime.long.map((info, index) => (
              <DataView key={index} data={info} />
            ))}
          </BoxWithPapers>
        </Layout.Col>
      </Layout.Row>
    </Layout.Container>
  );
};
