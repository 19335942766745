import { translation } from '../../translations/translations';

const DEFAULT_KEYWORDS = [translation.title, 'CV', translation.keywords.cv];
const SITE_URL =
  typeof window === 'object' && window?.initialValues?.REACT_APP_SITE_URL
    ? window.initialValues.REACT_APP_SITE_URL
    : process.env.REACT_APP_SITE_URL;

export const addHTMLSeo = ({
  siteName = translation.title,
  type = 'website',
  locale = 'hu',
  title = '',
  description = translation.description,
  keywords = [],
  image = `${SITE_URL}/images/profile.png`,
  url = `${SITE_URL}/`,
}) => {
  const siteTitle = title ? `${title} | ${siteName}` : siteName;
  let mixedKeywords = [...keywords];
  mixedKeywords = mixedKeywords.concat(DEFAULT_KEYWORDS);
  document.title = siteTitle;
  document.querySelector('meta[property="og:title"]').content = siteTitle;
  document.querySelector('meta[property="og:site_name"]').content = siteName;
  document.querySelector('meta[property="og:description"]').content =
    description;
  document.querySelector(
    'meta[property="og:url"]'
  ).content = `${SITE_URL}${url}`;
  document.querySelector('meta[property="og:type"]').content = type;
  document.querySelector('meta[property="og:local"]').content = locale;
  document.querySelector('meta[property="og:image"]').content = image;
  document.querySelector('meta[name="keywords"]').content =
    mixedKeywords.join(', ');
};

export const buildHTMLSeo = ({
  siteName = translation.title,
  type = 'website',
  locale = 'hu',
  title = '',
  description = translation.description,
  keywords = [],
  image = `${SITE_URL}/images/profile.png`,
  url = `${SITE_URL}/`,
}) => {
  const siteTitle = title ? `${title} | ${siteName}` : siteName;
  return (
    `<title>${siteTitle}</title>` +
    `<meta property="og:title" content="${siteTitle}">` +
    `<meta property="og:site_name" content="${siteName}">` +
    `<meta property="og:description" content="${description}">` +
    `<meta property="og:url" content="${SITE_URL}${url}">` +
    `<meta property="og:type" content="${type}">` +
    `<meta property="og:local" content="${locale}">` +
    `<meta property="og:image" content="${image}">` +
    `<meta name="keywords" content="${keywords.join(', ')}">`
  );
};
