import React from 'react';
import PropTypes from 'prop-types';

import { BoxContent } from './box-content/box-content';

export const BoxWithPapers = ({ header, link, children }) => (
  <div className="box-with-papers">
    <div className="box-inner">
      <BoxContent header={header} link={link}>
        {children}
      </BoxContent>
    </div>
  </div>
);

BoxWithPapers.prototype = {
  /**
   * Header of box.
   */
  header: PropTypes.string,
  /**
   * Internal or external link.
   * (internal starts with / charachter)
   */
  link: PropTypes.string,
  /**
   * Box content.
   */
  children: PropTypes.element.isRequired,
};
