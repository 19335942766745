import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAddressCard,
  faBriefcase,
  faGraduationCap,
  faCode,
  faGamepad,
} from '@fortawesome/free-solid-svg-icons';

import { PAGES } from '../../datas/pages/pages';

export const Navbar = () => {
  const intl = useIntl();
  const [isOpen, setIsOpen] = useState(false);

  const location = useLocation();
  const isHomePage = location.pathname === '/';
  const hash = location.hash.substring(1);

  const closeMenu = () => {
    setIsOpen(false);
  };

  const toogleMenu = () => {
    setIsOpen(!isOpen);
  };

  const isActive = (match, pageHash, isHomePageLink = false) => {
    return isHomePage
      ? hash === pageHash || (isHomePageLink && hash === '')
      : match?.isExact;
  };

  return (
    <div className={`component-navbar${isOpen ? ' open' : ''}`}>
      <nav className="no-select pb-half pt-half pl pr">
        <div className="navbar-pages-wrapper">
          <ul>
            <li>
              <NavLink
                to={isHomePage ? `#${PAGES.home.hash}` : PAGES.home.url}
                isActive={(match) => isActive(match, PAGES.home.hash, true)}
                title={intl.formatMessage({
                  id: 'pages.home',
                })}
              >
                <FontAwesomeIcon icon={faAddressCard} fixedWidth />
              </NavLink>
            </li>
            <li>
              <NavLink
                to={
                  isHomePage
                    ? `#${PAGES.workplaces.hash}`
                    : PAGES.workplaces.url
                }
                isActive={(match) => isActive(match, PAGES.workplaces.hash)}
                title={intl.formatMessage({
                  id: 'pages.workplaces',
                })}
              >
                <FontAwesomeIcon icon={faBriefcase} fixedWidth />
              </NavLink>
            </li>
            <li>
              <NavLink
                to={
                  isHomePage ? `#${PAGES.education.hash}` : PAGES.education.url
                }
                isActive={(match) => isActive(match, PAGES.education.hash)}
                title={intl.formatMessage({
                  id: 'pages.education',
                })}
              >
                <FontAwesomeIcon icon={faGraduationCap} fixedWidth />
              </NavLink>
            </li>
            <li>
              <NavLink
                to={
                  isHomePage
                    ? `#${PAGES.programmingSkills.hash}`
                    : PAGES.programmingSkills.url
                }
                isActive={(match) =>
                  isActive(match, PAGES.programmingSkills.hash)
                }
                title={intl.formatMessage({
                  id: 'pages.programmingSkills',
                })}
              >
                <FontAwesomeIcon icon={faCode} fixedWidth />
              </NavLink>
            </li>
            <li>
              <NavLink
                to={
                  isHomePage
                    ? `#${PAGES.leisureTime.hash}`
                    : PAGES.leisureTime.url
                }
                isActive={(match) => isActive(match, PAGES.leisureTime.hash)}
                title={intl.formatMessage({
                  id: 'pages.leisureTime',
                })}
              >
                <FontAwesomeIcon icon={faGamepad} fixedWidth />
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="navbar-hamburger-wrapper">
          <div className="button-wrapper">
            <button
              onClick={toogleMenu}
              aria-label={intl.formatMessage({
                id: 'hamburgermenu',
              })}
            >
              <div />
              <div />
              <div />
            </button>
          </div>
        </div>
      </nav>
      <div className="navbar-mobilemenu-wrapper">
        <ul>
          <li className="pl-2x pr-2x mb">
            <NavLink
              className="h2"
              to={isHomePage ? `#${PAGES.home.hash}` : PAGES.home.url}
              isActive={(match) => isActive(match, PAGES.home.hash, true)}
              onClick={closeMenu}
            >
              <FontAwesomeIcon icon={faAddressCard} fixedWidth />{' '}
              <FormattedMessage id="pages.home" />
            </NavLink>
          </li>
          <li className="pl-2x pr-2x mb">
            <NavLink
              className="h2"
              to={
                isHomePage ? `#${PAGES.workplaces.hash}` : PAGES.workplaces.url
              }
              isActive={(match) => isActive(match, PAGES.workplaces.hash)}
              onClick={closeMenu}
            >
              <FontAwesomeIcon icon={faBriefcase} fixedWidth />{' '}
              <FormattedMessage id="pages.workplaces" />
            </NavLink>
          </li>
          <li className="pl-2x pr-2x mb">
            <NavLink
              className="h2"
              to={isHomePage ? `#${PAGES.education.hash}` : PAGES.education.url}
              isActive={(match) => isActive(match, PAGES.education.hash)}
              onClick={closeMenu}
            >
              <FontAwesomeIcon icon={faGraduationCap} fixedWidth />{' '}
              <FormattedMessage id="pages.education" />
            </NavLink>
          </li>
          <li className="pl-2x pr-2x mb">
            <NavLink
              className="h2"
              to={
                isHomePage
                  ? `#${PAGES.programmingSkills.hash}`
                  : PAGES.programmingSkills.url
              }
              isActive={(match) =>
                isActive(match, PAGES.programmingSkills.hash)
              }
              onClick={closeMenu}
            >
              <FontAwesomeIcon icon={faCode} fixedWidth />{' '}
              <FormattedMessage id="pages.programmingSkills" />
            </NavLink>
          </li>
          <li className="pl-2x pr-2x mb">
            <NavLink
              className="h2"
              to={
                isHomePage
                  ? `#${PAGES.leisureTime.hash}`
                  : PAGES.leisureTime.url
              }
              isActive={(match) => isActive(match, PAGES.leisureTime.hash)}
              onClick={closeMenu}
            >
              <FontAwesomeIcon icon={faGamepad} fixedWidth />{' '}
              <FormattedMessage id="pages.leisureTime" />
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
};
