import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCode } from '@fortawesome/free-solid-svg-icons';

import * as Layout from '../../../layout';
import { Anchor } from '../../../common/anchor/anchor';
import { Box } from '../../../common/box/box';
import { PAGES } from '../../../../datas/pages/pages';
import { datas } from '../../../../datas/infos/infos';

export const ProgrammingSkills = () => {
  useEffect(() => {
    window.updateTagCloud(document.getElementById('programming-skills-cloud'));
    window.dispatchEvent(new Event('resize'));
  }, []);

  return (
    <Layout.Row>
      <Layout.Col col={12}>
        <Anchor hash={PAGES.programmingSkills.hash} />
        <Box
          header={
            <h2>
              <FontAwesomeIcon icon={faCode} fixedWidth />{' '}
              <FormattedMessage id="pages.programmingSkills" />
            </h2>
          }
          link={PAGES.programmingSkills.url}
        >
          <div id="programming-skills-cloud" data-cloud>
            {datas.programmingSkills.skillMatriy.map((item, index) => {
              const { name, color, weight, target } = item;
              return (
                <input
                  key={index}
                  type="hidden"
                  name="skill"
                  value={name}
                  data-weight={weight}
                  data-color={color}
                  data-target={target}
                />
              );
            })}
          </div>
        </Box>
      </Layout.Col>
    </Layout.Row>
  );
};
