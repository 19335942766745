import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';

import * as Layout from '../../layout';
import { addHTMLSeo } from '../../../utils/html-seo/html-seo';

export const NotFound = () => {
  const intl = useIntl();

  useEffect(() => {
    addHTMLSeo({
      title: intl.formatMessage({ id: 'pages.notFound' }),
      url: window.location.pathname,
    });
  }, [intl]);

  return (
    <Layout.Container className="page-notfound">
      <Layout.Row>
        <Layout.Col className="text-center" col={12}>
          <img
            src="/images/notfound/404.png"
            alt={intl.formatMessage({ id: 'pages.notFound' })}
          />
        </Layout.Col>
      </Layout.Row>
    </Layout.Container>
  );
};
